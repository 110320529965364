import request from './request'

//工序工位
export function GetPhoneMenu(data) {
  return request({
    url: '/index.php/filter/getPhoneMenu',
    method: 'post',
    data
  })
}
//纬度
export function noPageList(data) {
  return request({
    url: '/index.php/working_veidoo/no_page_list',
    method: 'post',
    data
  })
}
//分配拆解
export function dismantleAdd(data) {
  return request({
    url: '/index.php/working_dismantle/add',
    method: 'post',
    data
  })
}
//工序任务列表
export function flowGetList(data) {
  return request({
    url: '/index.php/working_dismantle/getList',
    method: 'post',
    data
  })
}
export function getStation(data) {
  return request({
    url: '/index.php/working_dismantle/getStation',
    method: 'post',
    data
  })
}
//预处理列表
export function noList(data) {
  return request({
    url: '/index.php/pretreatment/no_list',
    method: 'post',
    data
  })
}
export function gongwei(data) {
  return request({
    url: '/index.php/working_procedure/yu_chu_li_station_nopage_list',
    method: 'post',
    data
  })
}
export function yuchuliAdd(data) {
  return request({
    url: '/index.php/working_procedure_distribution/add',
    method: 'post',
    data
  })
}


