<template lang="">
    <div class="warper">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item,index) in images" :key="index" >
                <van-image
                    lazy-load
                    :src="item"
                    />
            </van-swipe-item>
        </van-swipe> 
        
        <div class="tab">
            <van-grid :column-num="3" :gutter="10" >
                <van-grid-item v-for="value in listS"  :key="value.id" :icon="value.io" :to="value.url" @click="go(value.id)" :text="value.name" class="grid-item" />
                <!-- <van-grid-item  icon="setting"  @click="go(9)" text="退出" class="grid-item" /> -->
            </van-grid>
         </div>

<!-- 
        <button @click="go(1)">证件上传/完备检查</button>
        <button @click="go(2)">拆解分配</button>
        <button @click="go(3)">拆解接收</button>
        <button @click="go(4)">新增临时拆买件</button>
        <button @click="go(5)">调度订单</button> -->
    </div>
</template>
<script>
import Vue from 'vue';
import {  setCookie ,getCookie,deleteCookie} from '../utils/env'
import { Lazyload ,Dialog} from 'vant';
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import {GetPhoneMenu} from '@/api/publics.js'
Vue.use(Lazyload);
export default {
    data(){
        return{
            listS:[],
            images:[
                require('../../public/images/banner_two.png'),
                require('../../public/images/banner_one.png'),
                // {image:'https://img01.yzcdn.cn/vant/apple-2.jpg'},
            ]
        }
    },
    created(){
        if(!getCookie('token')){
            this.$router.push('/login')
        }
        if(localStorage.getItem('listS')){
            this.listS = JSON.parse(localStorage.getItem('listS'))
        }
        //  let data = encipherMent( JSON.stringify({ pageSize: 10}))
        // let param = new URLSearchParams();
        // param.append("value",data);
        // param.append("page",1);
        // this.$http.post("/index.php/index/Disassemble_Allocation/dismantleReceive",param).then((res) => {
        
        //     if (res.data.code == -1) {
        //         this.islogout()
        //     }
        // })
        
        // if(window.location.href.search('hg-czs.com') == -1){
        // // if(window.location.href.search('8081') != -1){
        //     this.listS = [
        //         {id:7,name:'采购录入',io:'orders-o',url:'/Entering'},
        //         {id:6,name:'信息录入',io:'photo-o',url:'/Recognition'},
        //         // {id:1,name:'证件上传',io:'back-top'},
        //         {id:2,name:'拆解分配',io:'cluster-o',url:'/WaitingDisassembly'},
        //         {id:3,name:'拆解接收',io:'shop-o',url:'/receiving'},
        //         {id:4,name:'新增临时拆买件',io:'add-o',url:'/addNew'},
        //         {id:5,name:'厂外调度订单',io:'logistics',url:'/OrderList'},
        //          {id:13,name:'厂内调度订单',io:'logistics',url:'/SchedulingTheHall'},
        //         {id:8,name:'到场质检',io:'completed',url:'/Quality'},
        //         {id:10,name:'销户车入库',io:'peer-pay',url:'/ClosedCarStorage?id=1'},
        //         {id:11,name:'销户车库位查询',io:'question-o',url:'/ClosedCarStorage?id=2'},
        //         {id:14,name:'工序任务',io:'points',url:'/ProcessTask'},
        //         {id:15,name:'保全管理',io:'apps-o',url:'/SecurityManagement'},
        //         {id:16,name:'业务看板',io:'chart-trending-o',url:'/bulletinBoard'},
        //         {id:17,name:'OA审核',io:'sign',url:'/OA'},
        //         {id:9,name:'退出',io:'setting',url:'/'},
        //     ]
        // }
        this.getGuid()
    },
    methods: {
        async getGuid(){
            const {data} = await GetPhoneMenu().then((res) => res)
                let arr = data.data
                arr.forEach(i => {
                    if(i.id == 329){
                        this.$set(i,'io','orders-o')
                        this.$set(i,'url','/Entering')
                    }else if(i.id == 325){
                        this.$set(i,'io','photo-o')
                        this.$set(i,'url','/Recognition')
                    }else if(i.id == 571){
                        this.$set(i,'io','cluster-o')
                        this.$set(i,'url','/WaitingDisassembly')
                    }else if(i.id == 124){
                        this.$set(i,'io','shop-o')
                        this.$set(i,'url','/receiving')
                    }else if(i.id == 213){
                        this.$set(i,'io','add-o')
                        this.$set(i,'url','/addNew')
                    }else if(i.id == 337){
                        this.$set(i,'io','completed')
                        this.$set(i,'url','/Quality')
                    }else if(i.id == 205){
                        this.$set(i,'io','logistics')
                        this.$set(i,'url','/OrderList')
                    }else if(i.id == 572){
                        this.$set(i,'io','logistics')
                        this.$set(i,'url','/SchedulingTheHall')
                    }else if(i.id == 421){
                        this.$set(i,'io','peer-pay')
                        this.$set(i,'url','/ClosedCarStorage?id=1')
                    }else if(i.id == 573){
                        this.$set(i,'io','question-o')
                        this.$set(i,'url','/ClosedCarStorage?id=2')
                    }else if(i.id == 574){
                        this.$set(i,'io','points')
                        this.$set(i,'url','/ProcessTask')
                    }else if(i.id == 546){
                        this.$set(i,'io','apps-o')
                        this.$set(i,'url','/SecurityManagement')
                    }else if(i.id == 557){
                        this.$set(i,'io','chart-trending-o')
                        this.$set(i,'url','/bulletinBoard')
                    }else if(i.id == 575){
                        this.$set(i,'io','sign')
                        this.$set(i,'url','/OA')
                    }else if(i.id == 384){
                        this.$set(i,'io','tv-o')
                        this.$set(i,'url','/AGV')
                    }else if(i.id == 603){
                        this.$set(i,'io','todo-list-o')
                        this.$set(i,'url','/autoWeight')
                    }
                });
                let new_arr = []
                if(window.location.href.search('hg-czs.com') == -1){
                    arr.forEach(item => (item.id != 384 && new_arr.push(item)))
                }else{
                    new_arr = arr
                }
                let obj ={ id:9,name:'退出', io:"setting", url:""}
                new_arr.push(obj)
                this.listS = new_arr
                window.localStorage.setItem('listS',JSON.stringify(this.listS))
        },
         zhuan(url){
            let str = url
            let str1 = str.replace('https','')
            str1 = str1.replace('http','')
            let str2 = 'https'+ str1
            return str2
        },
        go(num){
            switch (num) {
                case 124:
                    let data = encipherMent( JSON.stringify({ pageSize: 10}))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    param.append("page",1);
                    this.$http.post("/index.php/index/Disassemble_Allocation/dismantleReceive",param).then((res) => {
                        let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
                        if (result.code == 0) {
                            if(result.data.data.length>0){
                                this.$router.push("/receiving")
                            }else{
                                this.$toast.fail("无数据")
                            }
                        }else if(result.code == '-1'){
                            this.$toast.fail(result.msg)
                            this.$router.push('/login')
                            deleteCookie('token')
                        
                        }else{
                            this.$toast.fail(result.msg)
                        }
                    });
                break;
                case 9:
                    Dialog.confirm({
                    title: '退出',
                    message: '您确定退出登陆吗？',})
                    .then(() => {this.islogout()})
                    
                break;
                default:
                    break;
            }
        }
    },
}
</script>
<style lang="scss" scoped> 
// .warper{
//     filter: grayscale(1)
// }
.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    height: 250px;
    line-height: 250px;
    text-align: center;
    background-color: #39a9ed;
    .van-image{
        width: 100%;
        height: 100%;
    }
}
.tab{
    margin-top: 30px;
}
.grid-item{
    height: 3rem;
    /deep/.van-grid-item__text {
        font-size: 18px;
    }
}

</style>